import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import axios from "axios";
import { config } from "../../config/config";

const initialState = {
  products: null,
  error: false,
  loading: false,
  address: null,
  delivery: null,
  addressLoader: false,
  orders: [],
};

//get main categories
export const getProducts = createAsyncThunk("/main-category", async () => {
  try {
    const newProducts = await axios
      .get(`${config.app.api_url}/main-category`)
      .then(async (response) => {
        return response;
      });
    return newProducts.data;
  } catch (err) {
    if (err.response) {
      throw err;
    }
    return isRejectedWithValue(err.response);
  }
});

//get all products
export const getAllProducts = createAsyncThunk("/products", async () => {
  try {
    const newProducts = await axios
      .get(`${config.app.api_url}/products`)
      .then(async (response) => {
        return response;
      });
    return newProducts.data;
  } catch (err) {
    if (err.response) {
      throw err;
    }
    return isRejectedWithValue(err.response);
  }
});

//get main category by id
export const getMainnCatId = createAsyncThunk(
  "/main-category/id",
  async (id) => {
    try {
      const newProducts = await axios
        .get(`${config.app.api_url}/main-category${id}`)
        .then(async (response) => {
          return response;
        });
      return newProducts.data;
    } catch (err) {
      if (err.response) {
        throw err;
      }
      return isRejectedWithValue(err.response);
    }
  }
);

//get currency by id
export const getCurrencyById = createAsyncThunk("/currency/id", async (id) => {
  try {
    const newProducts = await axios
      .get(`${config.app.api_url}/currency/${id.id}`)
      .then(async (response) => {
        return response;
      });
    return newProducts.data;
  } catch (err) {
    if (err.response) {
      throw err;
    }
    return isRejectedWithValue(err.response);
  }
});

export const createAddress = createAsyncThunk("/address", async (data) => {
  try {
    const newProducts = await axios
      .post(`${config.app.api_url}/address`, data)
      .then(async (response) => {
        return response;
      });
    return newProducts.data;
  } catch (err) {
    if (err.response) {
      throw err;
    }
    return isRejectedWithValue(err.response);
  }
});

export const getAddressByUser = createAsyncThunk(
  "/address/by-user/id",
  async (id) => {
    try {
      const newProducts = await axios
        .get(`${config.app.api_url}/address/by-user/${id}`)
        .then(async (response) => {
          return response;
        });
      return newProducts;
    } catch (err) {
      if (err.response) {
        throw err;
      }
      return isRejectedWithValue(err.response);
    }
  }
);

export const getOrderByUser = createAsyncThunk(
  "/order/by-user/id",
  async (id) => {
    try {
      const newProducts = await axios
        .get(`${config.app.api_url}/order/by-user/${id}`)
        .then(async (response) => {
          return response;
        });
      return newProducts?.data?.data;
    } catch (err) {
      if (err.response) {
        throw err;
      }
      return isRejectedWithValue(err.response);
    }
  }
);

export const deliveryByStore = createAsyncThunk(
  "/delivery/by-store/id",
  async (id) => {
    try {
      const newProducts = await axios
        .get(`${config.app.api_url}/delivery/by-store/${id}`)
        .then(async (response) => {
          return response;
        });
      return newProducts.data;
    } catch (err) {
      if (err.response) {
        throw err;
      }
      return isRejectedWithValue(err.response);
    }
  }
);

//add new product to cart
export const addToCart = createAsyncThunk("/cart", async (cartItem) => {
  // console.log(cartItem.store.name)
  try {
    var existingEntries = JSON.parse(localStorage.getItem("cartItem"));
    const checkId = (obj) => obj._id === cartItem._id;
    const checkStore = (obj) => obj?.store?.name !== cartItem?.store?.name;
    if (existingEntries === null) existingEntries = [];
    if (existingEntries.some(checkId)) {
      alert("item already exist");
      return {
        messsage: "item is in cart",
      };
    } else if (existingEntries.some(checkStore)) {
      alert("products must be from thesame store");
      return {
        messsage: "products must be from thesame store",
      };
    } else {
      existingEntries.push(cartItem);
      // console.log('check id', existingEntries)
      return localStorage.setItem("cartItem", JSON.stringify(existingEntries));
    }
  } catch {}
});

//delete product from cart
export const deleteFromCart = createAsyncThunk("/cart", async (getEl) => {
  try {
    const cartProducts = JSON.parse(localStorage.getItem("cartItem"));
    const retrieveItem = cartProducts.filter(function (elem) {
      return elem?._id !== getEl;
    });
    localStorage.setItem("cartItem", JSON.stringify(retrieveItem));
    getEl?.parentNode?.parentNode?.removeChilde(getEl?.parentNode);
  } catch {}
});

//cancel order
export const cancelOrder = createAsyncThunk(
  "/order/canceled/id",
  async (order) => {
    const token = JSON.parse(localStorage.getItem("user"));
    const user_token = token.data.access_token;
    try {
      const newProducts = await axios
        .patch(`${config.app.api_url}/order/canceled/${order._id}`,order, {
          headers: {
            Authorization: "Bearer " + user_token,
          },
        })
        .then(async (response) => {
          return response;
        });
      return newProducts?.data?.data;
    } catch (err) {
      if (err.response) {
        throw err;
      }
      return isRejectedWithValue(err.response);
    }
  }
);
export const receivedOrder = createAsyncThunk(
  "/order/received/id",
  async (order) => {
    const token = JSON.parse(localStorage.getItem("user"));
    const user_token = token.data.access_token;
    try {
      const newProducts = await axios
        .patch(`${config.app.api_url}/order/received/${order._id}`,order, {
          headers: {
            Authorization: "Bearer " + user_token,
          },
        })
        .then(async (response) => {
          return response;
        });
      return newProducts?.data?.data;
    } catch (err) {
      if (err.response) {
        throw err;
      }
      return isRejectedWithValue(err.response);
    }
  }
);

const marketSlice = createSlice({
  name: "marketplace",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get products from main category
    builder.addCase(getProducts.pending, (state) => {
      state.loading = true;
      state.error = false;
    });

    builder.addCase(getProducts.fulfilled, (state, action) => {
      state.loading = false;
      state.products = action.payload;
    });

    //get all products
    builder.addCase(getAllProducts.pending, (state) => {
      state.loading = true;
      state.error = false;
    });

    builder.addCase(getAllProducts.fulfilled, (state, action) => {
      state.loading = false;
      state.products = action.payload;
    });

    //getting main category by  id
    builder.addCase(getMainnCatId.pending, (state) => {
      state.loading = true;
      state.error = false;
    });

    builder.addCase(getMainnCatId.fulfilled, (state, action) => {
      state.loading = false;
      state.products = action.payload;
    });

    // manipulate local storage
    builder.addCase(addToCart.pending, (state) => {
      state.loading = true;
      state.error = false;
    });

    builder.addCase(addToCart.fulfilled, (state, action) => {
      state.loading = false;
      state.products = action.payload;
    });

    //get currency
    builder.addCase(getCurrencyById.pending, (state) => {
      state.loading = true;
      state.error = false;
    });

    builder.addCase(getCurrencyById.fulfilled, (state, action) => {
      state.loading = false;
      state.products = action.payload;
    });

    builder.addCase(createAddress.pending, (state) => {
      state.addressLoader = true;
      state.error = false;
    });

    builder.addCase(createAddress.fulfilled, (state, action) => {
      state.addressLoader = false;
      state.address = action.payload;
    });

    builder.addCase(createAddress.rejected, (state, action) => {
      state.addressLoader = false;
      state.address = action.payload;
    });

    builder.addCase(getAddressByUser.pending, (state) => {
      state.loading = true;
      state.error = false;
    });

    builder.addCase(getAddressByUser.fulfilled, (state, action) => {
      state.loading = false;
      state.address = action.payload;
    });

    builder.addCase(deliveryByStore.pending, (state) => {
      state.loading = true;
      state.error = false;
    });

    builder.addCase(deliveryByStore.fulfilled, (state, action) => {
      state.loading = false;
      state.delivery = action.payload;
    });

    //get orders by current user

    builder.addCase(getOrderByUser.pending, (state) => {
      state.loading = true;
      state.error = false;
    });

    builder.addCase(getOrderByUser.fulfilled, (state, action) => {
      state.loading = false;
      state.orders = action.payload;
    });

    builder.addCase(getOrderByUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    //cancel order
    builder.addCase(cancelOrder.pending, (state) => {
      state.loading = true;
      state.error = false;
    });

    builder.addCase(cancelOrder.fulfilled, (state, action) => {
      state.loading = false;
      state.orders = action.payload;
    });

    builder.addCase(cancelOrder.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

     //receive order
     builder.addCase(receivedOrder.pending, (state) => {
      state.loading = true;
      state.error = false;
    });

    builder.addCase(receivedOrder.fulfilled, (state, action) => {
      state.loading = false;
      state.orders = action.payload;
    });

    builder.addCase(receivedOrder.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});
export default marketSlice.reducer;
