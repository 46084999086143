import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import axios from "axios";
import { config } from "../../config/config";

const initialState = {
  user: null,
  error: false,
  loading: false,
};

export const requestVerification = createAsyncThunk(
  "/request-verification-code",
  async (data) => {
    try {
      const response = await axios
        .post(`${config.app.api_url}/request-verification-code`, data)
        .then((res) => {
          return res;
        });
      console.log(response);
      return response;
    } catch (err) {}
  }
);

export const userLogin = createAsyncThunk("/verify-phone", async (data) => {
  try {
 const res =   await axios
      .post(`${config.app.api_url}/verify-phone`, data)
      .then(async (response) => {
        localStorage.setItem("user", JSON.stringify(response));
        if (response.data.verificationStatus.status === "approved") {
          const userLog = response.data.user;
          userLog.token = response.data.access_token;
        }
        return response
      });
      return res
  } catch (err) {
    if (err.response) {
      throw err;
    }
    return isRejectedWithValue(err.response);
  }
});

export const registerUser = createAsyncThunk("/register", async (data) => {
  try {
    const newUser = await axios
      .post(`${config.app.api_url}/register`, data)
      .then((response) => {
        return response;
      });
    return newUser;
  } catch (error) {}
});

export const getLocalUser = createAsyncThunk("/market-place", async () => {
  const newUser = localStorage.getItem("user");
  return JSON.parse(newUser);
});

export const logOut = createAsyncThunk("/market-place", async () => {
  const newUser = localStorage.removeItem("user");
  return newUser
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //register uer
    builder.addCase(registerUser.pending, (state) => {
      state.loading = true;
      state.error = false;
    });

    builder.addCase(registerUser.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
    });

    //request verification
    builder.addCase(requestVerification.pending, (state) => {
      state.loading = true;
      state.error = false;
    });

    builder.addCase(requestVerification.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
    });
    builder.addCase(requestVerification.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });

    //verify user
    builder.addCase(userLogin.pending, (state) => {
      state.loading = true;
      state.error = false;
    });

    builder.addCase(userLogin.rejected, (state) => {
      state.loading =false;
      state.error = true;
    });

    builder.addCase(userLogin.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
    });
    //get local user
    builder.addCase(getLocalUser.pending, (state) => {
      state.loading = true;
      state.error = false;
    });

    builder.addCase(getLocalUser.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
    });
  },
});

export default authSlice.reducer;
