import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { config } from "../../config/config";
import axios from "axios";
const initialState = {
  error: false,
  loading: false,
  success: null,
};

const API_KEY = config.CINET.API_KEY;
const APP_ID = config.CINET.APP_ID;
const PAYMENT_URL = "https://api-checkout.cinetpay.com/v2/payment";

export const makePayment = createAsyncThunk("makePayment", async (values) => {
  console.log("start payment");
  const ref = Math.floor(Math.random() * 100000000).toString();
  const data = JSON.stringify({
    apikey: API_KEY,
    site_id: APP_ID,
    transaction_id: ref,
    amount: values.amount,
    currency: "XAF",
    alternative_currency: "",
    description: "Achat des articles sur Kalio.",
    customer_name: values.address1.firstName,
    customer_surname: values.address1.lastName,
    customer_phone_number: "+237" + values.address1.phone,
    customer_address: values.address1.streetAddress,
    customer_city: values.address1.city,
    customer_country: "CM",
    customer_state: "CM",
    customer_zip_code: "67589",
    notify_url: "http://hook.kalio.io/hook-test.php",
    return_url: "http://hook.kalio.io/index.php",
    channels: "ALL",
    metadata: "user1",
    lang: "EN",
    invoice_data: {
      Donnee1: "",
      Donnee2: "",
      Donnee3: "",
    },
  });

  // console.log('data', data);
  const token = JSON.parse(localStorage.getItem("user"));
  const user_token = token.data.access_token;
  const configuration = {
    method: "POST",
    url: PAYMENT_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer "  + user_token,
    },
    data: data,
  };

 const payResponse = axios(configuration)
    .then(function (response) {
      if (response.data.code === "201") {
      
        let transactionData = {
          userId: values.user_id,
          products: JSON.stringify(values.products),
          address:values.address,
          store: values.store,
          ref: ref,
          totalcost:JSON.stringify(values.totalcost),
          destination:values.destination,
        };
        const configurationData = {
          method: "POST",
          url: `${config.app.api_url}/order`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + user_token,
          },
          data: transactionData,
        };
          const response1 = axios(configurationData)
            .then((resp) => {
              if (resp?.data?.status === "success") {
                window.open(response.data.data.payment_url)
              }
              console.log(resp.data)
              return resp.data
            })
            .catch((error) => {
              console.error("Order Place", error);
              return error;
            });
            // console.log(response.data)
          return response1;

      }
      
      // return response
    })
    .catch(function (error) {
      return error
    })
    return payResponse
});

const cinetPay = createSlice({
  name: "cinetPay",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(makePayment.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(makePayment.fulfilled, (state, action) => {
      state.success = action.payload;
      state.error = null;
      state.loading = false
    });
    builder.addCase(makePayment.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default cinetPay.reducer;
