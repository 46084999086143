import React from "react";
import { BsFillPersonFill, BsShop } from "react-icons/bs";
import { ImLab } from "react-icons/im";
import { IoMapOutline } from "react-icons/io5";
import { MdSensors, MdShop } from "react-icons/md";
import { TbPlant, TbSeeding } from "react-icons/tb";
import { useTranslation } from "react-i18next";

import img from "../assets/image.png";

const NewFeature = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="mb-10">
        <section className="text-gray-600 body-font pb-20">
          <div className="container px-5 pt-20 pb-2 mx-auto flex flex-wrap justify-evenly">
            <h2
              style={{ marginLeft: "6rem" }}
              className="sm:text-3xl text-2xl text-gray-900 font-medium title-font mb-2 md:w-2/5"
            >
              {t("feature.1")}
            </h2>
            <div className="md:w-3/5 md:pl-6"></div>
            <div className="space-y-8 ml-10 mt-20 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
              <div>
                <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 ">
                  <span className="inline-block p-3 text-green-700 bg-green-700 bg-opacity-5 rounded-full">
                    <TbPlant />
                  </span>
                </div>
                <h3 className="mb-2 text-xl font-bold dark:text-gray-800">
                  {t("feature.3")}
                </h3>
                <p className="text-gray-700 text-base">{t("feature.4")}</p>
              </div>
              <div>
                <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 ">
                  <span className="inline-block p-3 bg-green-700 bg-opacity-5 text-green-700 rounded-full">
                    <MdSensors />
                  </span>
                </div>
                <h3 className="mb-2 text-xl font-bold dark:text-gray-800">
                  {t("feature.5")}
                </h3>
                <p className="text-gray-700 text-base">{t("feature.6")}</p>
              </div>
              <div>
                <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 ">
                  <span className="inline-block p-3 bg-green-700 bg-opacity-5 text-green-700  rounded-full">
                    <TbSeeding />
                  </span>
                </div>
                <h3 className="mb-2 text-xl font-bold">{t("feature.13")}</h3>
                <p className="text-gray-700 text-base">{t("feature.14")}</p>
              </div>
            </div>
          </div>
        </section>

        <section className="text-gray-600 body-font bg-gray-900 pb-10">
          <div className="container px-5 pt-20 pb-2 mx-auto flex flex-wrap justify-evenly">
         
            <div className="md:w-3/5 md:pl-6">
              <h2
                style={{ textAlign: "center", marginLeft: "10rem" }}
                className="sm:text-3xl text-2xl text-white font-medium title-font mb-2 md:w-2/5"
              >
                {t("feature.9")}
              </h2>
            </div>
            <div style={{marginLeft:'15rem'}} className="space-y-8 mt-20 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
              <div style={{width:'100%', display:'flex', flexDirection:'column', alignItems:'center'}}>
                <div className="mb-4 h-10 rounded-full bg-primary-100 lg:h-12 ">
                  <span className="inline-block p-3 text-green-700 bg-white rounded-full">
                    <BsFillPersonFill />
                  </span>
                </div>
                <h3  className="mb-2 text-xl font-bold text-white">
                  {t("feature.11")}
                </h3>
              </div>
              <div style={{width:'100%', display:'flex', flexDirection:'column', alignItems:'center'}}>
                <div className="flex  items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 ">
                  <span className="inline-block p-3 text-green-700 bg-white rounded-full">
                    <ImLab />
                  </span>
                </div>
                <h3 style={{width:'100%'}} className="mb-2 text-xl font-bold text-white">
                  {t("feature.12")}
                </h3>
              </div>
            </div>
          </div>
        </section>
        <section className="text-gray-600 body-font bg-white mt-4">
          <div className="flex flex-wrap w-full bg-white py-10 px-10">
            <img
              alt="gallery"
              className="w-full object-cover h-full object-center block"
              src={img}
            />
          </div>
        </section>
      </div>
    </>
  );
};

export default NewFeature;
