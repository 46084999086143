import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../slices/authSlice'
import marketReducer from '../slices/marketSlice'
import cinetPayReducer from '../slices/cinetPaySlice'
import transportationReducer from '../slices/transportation'
import postReducer from '../slices/post.tutorial'
export const store = configureStore({
  reducer: {
   auth:authReducer,
   marketplace: marketReducer,
   cinetPay:cinetPayReducer,
   transporter: transportationReducer,
   postTutorial: postReducer,
  },
})