import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Login from './auth/Login'
import Register from './auth/register'




function AuthRoutes() {
  return (
    <Routes>
         <Route path="/delete-my-account" element={<Register/>} />
         <Route path="/confirm-account-deletion" element={<Login/>} />
    </Routes>
  )
}

export default AuthRoutes