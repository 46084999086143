import * as React from "react";
import Nav from "./components/Nav";
import Router from "./router";
import AuthRoutes from "./authRoutes";
import { toast, ToastContainer } from "react-toastify";
import "./index.css";
import { createChatUsers, getChatUsers } from "./state/slices/chatSlice";
const App = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  React.useEffect(() => {
    const userPhone = user?.data?.user;
    getChatUsers().then((res) => {
      const sameNumber = res?.data?.map((data1) => data1.phone);
      const newNumber = sameNumber.find((num) => num === userPhone?.phone);
   
      const addTolocal = (data) =>{
        data?.forEach((item) =>{
          if(item?.phone === userPhone?.phone){
            localStorage.setItem("chatUser", JSON.stringify(item));
            console.log("item", item);
        }})
      }
      addTolocal(res?.data)
      console.log('userPhone', userPhone?.phone);
      if (newNumber) {
        return;
      } else {
        createChatUsers({
          username: userPhone.name,
          phone:userPhone?.phone,
          avatar: '',
          typeUser:'user'
        }).then((response) => {
          localStorage.setItem("chatUser", JSON.stringify(response.data));
        });
      }
    });
  }, [user]);
  
  return (
    <div className="App">
      <Nav />
      <Router />
      <AuthRoutes />
      <ToastContainer />
    </div>
  );
};

export default App;
