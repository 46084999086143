import { Box, Button, Divider } from "@mui/material";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import React, { useState } from "react";
import { TbScan } from "react-icons/tb";
import { TiTick } from "react-icons/ti";
import "./auth.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { registerUser } from "../state/slices/authSlice";
import { Phone } from "@mui/icons-material";
import { requestAccountDeletion } from "../state/slices/deleteSlice";

function Register() {
  const [phoneNumber, setphoneNumber] = useState();
  const [confirmNumber, setConfirmNumber] = useState();
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState();
  const registerSuccess = (data) => toast.error(data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toastError =  () => toast.error('phone confirmation number does not match')
  const toastRequestError =() => toast.error('Oops something went wrong please check your  inputs')
   const requestCode = () => {
    try {
      const createNewDeviceType = async () => {
        setLoading(true);
       if(confirmNumber === phoneNumber){
        await requestAccountDeletion({
          phone:phoneNumber
        })
          .then((res) => {
            if(res?.data?.success === true){
              navigate('/confirm-account-deletion', {state:phoneNumber})
            }else{
              registerSuccess(res?.data?.message)
            }
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            toastRequestError()
            setLoading(false);
          });
       }else{
        toastError()
       }
      };
      createNewDeviceType();
    } catch (error) {
      toastRequestError()
      console.log(error);
      setLoading(false);
    }
  };
  
console.log('confirmation nummber', confirmNumber)
console.log('phone nummber', phoneNumber)
  return (
    <Box>
      <section className="text-gray-400 bg-green-700 body-font mb-20">
        <div className="container mx-auto pt-10 flex px-5 py-10 md:flex-row flex-col items-center">
          <div className="lg:flex-grow mt-10 lg:mt-0 pt-12 md:pt-16 sm:pt-16 md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white sm:mt-10 lg:mt-10 text-justify lg:text-left">
              Request To Delete My Account
            </h1>
          </div>
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 lg:pt-20 "></div>
        </div>
      </section>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "0 auto",
          justifyContent: "center",
          alignItems: "center",
          width: "50%",
        }}
      >
        <div style={{ width: "100%" }}>
          <p style={{ textAlign: "left", marginLeft: "2rem" }}>
            Enter phone Number
          </p>
          <PhoneInput
            international
            defaultCountry="CM"
            value={phoneNumber}
            onChange={setphoneNumber}
            flags={true}
            countryCallingCodeEditable={false}
            className="input"
          />
        </div>

        <div style={{ width: "100%" }}>
          <p style={{ textAlign: "left", marginLeft: "2rem" }}>
            Confirm phone number
          </p>
          <PhoneInput
            international
            defaultCountry="CM"
            value={confirmNumber}
            onChange={setConfirmNumber}
            flags={true}
            countryCallingCodeEditable={false}
            className="input"
          />
        </div>
        {loading ? (
          <Button className="register-btn loading">Loading.........</Button>
        ) : (
          <Button
            onClick={requestCode}
            className="register-btn"
            style={{ backgroundColor: "#208143", color:'white'}}
          >
            Confirm
          </Button>
        )}
        <ToastContainer />
      </div>
    </Box>
  );
}

export default Register;