import { Box, Button, Card, Chip, Typography } from "@mui/material";
import React, { useEffect } from "react";
import "./marketplace.scss";
import { Stack } from "react-bootstrap";
import { ArrowBack, FiberManualRecord } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { imgURL, imgURLPost } from "../utils/apiImg";
import { useTranslation } from "react-i18next";

export default function PostDetail() {
  const state = useLocation();
  const {t} = useTranslation()
  const navigate = useNavigate()
  useEffect(() =>{
    console.log(state.state);
  }, [state])
  return (
    <Box className="blog">
      <Typography className="top-text bolder">{state.state.name}</Typography>
      <Typography className="top-text top">
      {t('blogs.3')}
      </Typography>
      <Card className="over-lay">
        {state.state.image ? (
          <img
            src={`${imgURLPost}/${state?.state?.image}`}
            alt=""
          />
        ) : (
          <img src="/assets/images/blog1.jpeg" alt="" />
        )}

        <div className="ab-div">
          <Box style={{ marginTop: "15rem" }}>
            <Typography className="author">
              etah innocent <FiberManualRecord className="point" />
              {moment(state.state.createdAt).format("DD/MMMM/YY")}
            </Typography>
            <Box className="blog-title">
              <Typography className="title-text">{state.state.name}</Typography>
            </Box>
            <Typography className="blog-desc">
              {state.state.typepost.description}
            </Typography>
            <Stack direction="row" spacing={1} className="chip-con">
              <Chip
                label={state.state.typepost.name}
                variant="outlined"
                className="chip"
              />
            </Stack>
          </Box>
        </div>
      </Card>
      <Box className="new-blog">
        <Typography>{state.state.description}</Typography>
      </Box>
      <Button
        style={{
          display: "block",
          backgroundColor: "#15803d",
          padding: "1rem",
          borderRadius: "50%",
          marginLeft:'93%'
        }}
        onClick={() => navigate('/blogs')}
      >
        <ArrowBack
          style={{
            color: "white",
          }}
        />
      </Button>
    </Box>
  );
}
