import { Box, Button, Divider } from "@mui/material";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import React, { useState } from "react";
import { TbScan } from "react-icons/tb";
import { TiTick } from "react-icons/ti";
import { requestAccountDeletion,confirmAccountDeletion} from "../state/slices/deleteSlice";
import "./auth.scss";
import { useLocation, useNavigate } from "react-router-dom";
import GetOtp from "./getOtp";
import { getLocalUser, requestVerification } from "../state/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

function Login() {
  const [loading, setLoading] = useState()
  const [otp, setOtp] = useState();
  const toastError =  () => toast.error('some thing went wrong please try again later')
  const toastSuccess =  () => toast.success('Your  account was  successfully deleted')
  const state = useLocation();
console.log(state)
  const requestCode = () => {
    try {
      const createNewDeviceType = async () => {
        setLoading(true);
        await confirmAccountDeletion({
          phone:state?.state,
          code:otp
        })
          .then(() => {
            toastSuccess()
            setLoading(false);
          })
          .catch((error) => {
            toastError()
            setLoading(false);
          });
       
      };
      createNewDeviceType();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return (
  <Box>
    <ToastContainer/>
    <section className="text-gray-400 bg-green-700 body-font mb-20">
        <div className="container mx-auto pt-10 flex px-5 py-10 md:flex-row flex-col items-center">
          <div className="lg:flex-grow mt-10 lg:mt-0 pt-12 md:pt-16 sm:pt-16 md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white sm:mt-10 lg:mt-10 text-justify lg:text-left">
              Confirm Account Deletion
            </h1>
          </div>
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 lg:pt-20 "></div>
        </div>
      </section>
      <div
      style={{
        display: "flex", 
        width:'50%',
        justifyContent: "center",
        alignItems:'center',
        flexDirection: "column",
        margin: "0 auto",
      }}
    >
      <p className="dis-msg">
        Please provide the code that was just sent to
        <span style={{color:'#208143'}}>{state?.state}</span>
      </p>
      <input
        style={{height: '40px',
          border: '1px solid  rgb(224, 224, 224)',
          borderRadius: '5px',
          width: '40%',
          marginTop: '2rem',
          outline: 'none'}}
        type="text"
        value={otp}
        onChange={(e) => setOtp(e.target.value)}
      />
      {loading ? (
        <Button className="confirm">Loading........</Button>
      ) : (
        <Button sx={{marginTop:'1rem'}} onClick={requestCode}>Confirm</Button>
      )}
    </div>
  </Box>
  );
}

export default Login;
