import React from "react";

import CTA from "../components/CTA";
import FAQ from "../components/FAQ";
import Feature from "../components/Feature";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import HeroButton from "../components/HeroButton";
import Nav from "../components/Nav";
import Sections from "../components/Sections";
import heroImg from "../assets/bg.png";

import ContactForm from "../components/ContactForm";

import { useNavigate } from "react-router-dom";
import HomeHero from "../components/HomeHero";
import Body from "../components/Body";
import NewFeature from "../components/NewFeature";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const heroData = {
    title: "Smart Agriculture with IoT",
    // text: "We use AI/ML & IoT Technologies to Monitor your Crops and Connect you to our Experts partners to optimize your Yield.",
    // text1: "",
    image: heroImg,
    button: (
      <HeroButton
        children={t("intro.8")}
        // onClick={() => navigate("./become-partner")}
      />
    ),
  };

  return (
    <>
      {/* <Nav /> */}
      {/* <Hero
        title={heroData.title}
        text={heroData.text}
        img={heroData.image}
        button={heroData.button}
      /> */}
      <HomeHero
        title={heroData.title}
        text={heroData.text}
        img={heroData.image}
        button={heroData.button}
      />
      {/* <Feature /> */}
      <NewFeature />
      {/* <Sections /> */}
      <CTA />
      {/* <FAQ /> */}

      <Footer />
    </>
  );
};

export default Home;
