import { Box, Card, Chip, Pagination, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import BlogCards from "../components/blogCards";
import "./marketplace.scss";
import { Stack } from "react-bootstrap";
import { ArrowBack, ArrowForward, FiberManualRecord } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { getPost, getTutorial } from "../state/slices/post.tutorial";
import moment from "moment/moment";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { imgURL, imgURLPost } from "../utils/apiImg";
import i18next from "i18next";

export default function BlogPage() {
  const [posts, setPosts] = useState();
  const [tutorial, setTutorial] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(24);
  const {t} = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPost(i18next.language || 'en')).then((res) => {
      setPosts(res?.payload);
    });
  }, [i18next.language,dispatch]);


  useEffect(() => {
    dispatch(getTutorial(i18next.language || 'en')).then((res) => {
      setTutorial(res?.payload);
    });
  }, [i18next.language,dispatch]);

  console.log('all tutorials', tutorial);
  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <Box className="blog">
      <Typography className="top-text top">{t('blogs.1')}</Typography>
      <Typography className="top-text bolder">
      {t('blogs.2')}
      </Typography>
      <Typography className="top-text top">
      {t('blogs.3')}
      </Typography>
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={45}
        totalSlides={posts?.length}

      >
        <Slider>
          {posts?.map((data, index) => (
            <Slide index={index}>
              <Card className="over-lay">
                <img
                  src={`${imgURLPost}/${data.image}`}
                  alt=""
                />
                <div className="ab-div">
                  <Box style={{ marginTop: "15rem" }}>
                    <Typography className="author">
                      etah innocent <FiberManualRecord className="point" />
                      {moment(data.createdAt).format("DD/MMMM/YY")}
                    </Typography>
                    <Box className="blog-title">
                      <Typography className="title-text">
                        {data.name}
                      </Typography>
                    </Box>
                    <Typography className="blog-desc">
                      {data.typepost.name.description}
                    </Typography>
                    <Stack direction="row" spacing={1} className="chip-con">
                      <Chip
                        label={data.typepost.name}
                        variant="outlined"
                        className="chip"
                      />
                       <Chip
                        label={t('blogs.4')}
                        variant="outlined"
                        className="chip"
                        style={{cursor:'pointer'}}
                        onClick={() => navigate(`/post/${data._id}`, {state:data, })}
                      />
                    </Stack>
                  </Box>
                </div>
              </Card>
            </Slide>
          ))}
        </Slider>
        <ButtonBack
          style={{
            position: "absolute",
            top: "14rem",
            left: "1rem",
            color: "white",
            backgroundColor:'#15803d',
            padding:'1rem',
            borderRadius:'50%'
          }}
        >
          <ArrowBack/>
        </ButtonBack>
        <ButtonNext  style={{
            position: "absolute",
            top: "14rem",
            right: "1rem",
            color: "white",
            backgroundColor:'#15803d',
            padding:'1rem',
            borderRadius:'50%'
          }}>
          <ArrowForward/>
        </ButtonNext>
      </CarouselProvider>
      <Box className="new-blog">
        {tutorial
          ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          ?.map((data) => (
            <BlogCards
              description={data.description}
              name={data.name}
              img={"/assets/images/blog1.jpeg"}
              video={data.lien}
              postId={data._id}
              image={data.image}
              typepost={data.typepost}
              createdAt={data.createdAt}
            />
          ))}
      </Box>
      <Stack spacing={2}>
        <Pagination
          count={posts?.length}
          variant="outlined"
          color="primary"
          style={{
            marginLeft: "33%",
            marginTop: "2rem",
          }}
          rowsPerPage={rowsPerPage}
          page={page}
          onChange={handleChange}
        />
      </Stack>
    </Box>
  );
}
