import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import Select from "react-select";
import CountrySelect from "react-bootstrap-country-select";
import { useTranslation } from "react-i18next";
import { BsShop, BsFillPersonFill, BsAsterisk } from "react-icons/bs";
import { ImLab } from "react-icons/im";

import Button from "../components/Button";
import Nav from "../components/Nav";
import axios from "axios";
import Loader from "../components/Loader";
import { MdShop } from "react-icons/md";
import { Shop } from "@mui/icons-material";
import { config } from "../config/config";

function Partner() {
  const { t } = useTranslation();
  const specialities = [
    { value: 1, label: "Seed Vendor" },
    { value: 2, label: "Laboratory" },
    { value: 3, label: "Agronomist" },
  ];

  const services = [
    // {
    //   title: t("partners.4"),
    //   description: t("partners.5"),
    //   icon: <BsShop />,
    // },
    // {
    //   title: t("partners.15"),
    //   description: t("partners.5"),
    //   icon: <MdShop />,
    // },
    {
      title: t("partners.6"),
      description: t("partners.7"),
      icon: <BsFillPersonFill />,
    },
    {
      title: t("partners.8"),
      description: t("partners.9"),
      icon: <ImLab />,
    },
  ];

  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedOptions, setSelectedOptions] = useState(null);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setField("website", "https://"+form.website);
    axios
      .post(`${config.app.api_url}/partner`, form, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then((response) => {
        setLoading(false);
        console.log("response ", response);
        if (response.data.status == "success") {
          setMessage(response.data.message);
          setError(false);
        } else {
          setMessage(response.data.message);
          setError(true);
        }
        return response;
      })
      .catch((e) => {
        console.log("error", e);
        setMessage("Error when processing your request");
        setError(true);
        setLoading(false);
        return null;
      });
  };

  const setHandle = (e) => {
    setSelectedOptions(Array.isArray(e) ? e.map((hotel) => hotel.label) : []);
  };

  const forms = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    console.log(form)
    // emailjs
    //   .sendForm(
    //     "service_hd3ia1m",
    //     "template_amdx4cx",
    //     forms.current,
    //     "JMVnd10zX6tQdMQDr"
    //   )
    //   .then(
    //     (result) => {
    //       console.log(result.text);
    //       console.log("message sent");
    //     },
    //     (error) => {
    //       console.log(error.text);
    //     }
    //   );
  };

  return (
    <>
      {/* <Nav /> */}
      {/* {loading && <Loader />} */}
      <section className="text-gray-400 bg-green-700 body-font mb-20">
        <div className="container mx-auto pt-10 flex px-5 py-10 md:flex-row flex-col items-center">
          <div className="lg:flex-grow mt-10 lg:mt-0 pt-12 md:pt-16 sm:pt-16 md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white sm:mt-10 lg:mt-10 text-justify lg:text-left">
              {t("partners.1")}
            </h1>
            <p className="leading-relaxed text-gray-100 text-left lg:text-left">
              {t("partners.2")}
            </p>
          </div>
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 lg:pt-20 "></div>
        </div>
      </section>


      <section className="text-gray-600 body-font pb-20">
        <div className="container px-5 mx-auto">
          <div className="flex flex-col text-center w-full mb-10">
            {/* <h2 className="text-xs text-green-700 tracking-widest font-medium title-font mb-1">
              Services for Kalio Partners
            </h2> */}
            <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900">
              {t("partners.3")}
            </h1>
          </div>
          <div 
          className="flex flex-wrap -m-4"
          style={{width:'100%', display:'flex', justifyContent:'space-evenly', flexWrap:'wrap'}}
          >
            {services.map((service) => (
              <div className="p-4 md:w-1/3" key={service.title}>
                <div className="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
                  <div className="flex items-center mb-3">
                    <div className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-green-700 text-white flex-shrink-0">
                      {service.icon}
                    </div>
                    <h2 className="text-gray-900 text-m title-font font-medium">
                      {service.title}
                    </h2>
                  </div>
                  <div className="flex-grow">
                    <p className="leading-relaxed text-base">
                      {service.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section id="#becomepartner" className="bg-white lg:mx-20 mx-14">
        <div className="mx-auto">
          <div className="flex flex-col text-center w-full mb-10">
            <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900">
              {t("partners.10")}
            </h1>
          </div>
          <div className="">
            <p> {t("partners.11")}</p>
          </div>
          <div className="mt-10 sm:mt-0">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1 mt-20 ">
                <div className="px-4 sm:px-0">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    {t("partners.12")}
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">
                    {t("partners.13")}
                  </p>
                </div>
                {/* <div className="md:grid md:grid-cols-3 md:gap-6"> */}
                {/* <div className="md:col-span-1">
                  <div className="px-4 sm:px-0 py-0 lg:pt-0 pt-8 mt-96">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Business Information
                    </h3>
                    <p className="mt-1 text-sm text-gray-600">
                      Tell us about your business. This information will be used
                      to verify the validity of your business
                    </p>
                  </div>
                </div> */}
                {/* </div> */}
              </div>
              <div className="mt-5 md:mt-0 md:col-span-2">
                <form ref={forms} action="#" method="POST" onSubmit={handleSubmit}>
                  <div className="shadow overflow-hidden sm:rounded-md">
                    <div className="px-4 py-5 bg-white sm:p-6">
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="firstname"
                            className="flex items-center text-sm font-medium text-gray-700"
                          >
                            {t("form.0")}
                            <span>
                              <BsAsterisk className="ml-2 text-xs" />
                            </span>
                          </label>
                          <input
                            type="text"
                            name="firstname"
                            id="firstname"
                            autoComplete="given-name"
                            placeholder={t("form.0")}
                            className={
                              !!errors.firstname
                                ? "mt-1  focus:ring-green-700 focus:border-green-700 block w-full shadow-sm sm:text-sm border-red-700 rounded-md"
                                : "mt-1  focus:ring-green-600 focus:border-green-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            }
                            value={form.firstname}
                            onChange={(e) =>
                              setField("firstname", e.target.value)
                            }
                          // isInvalid={!!errors.firstName}
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="last-name"
                            className="flex items-center text-sm font-medium text-gray-700"
                          >
                            {t("form.17")}
                            <span>
                              <BsAsterisk className="ml-2 text-xs" />
                            </span>
                          </label>
                          <input
                            type="text"
                            name="lastname"
                            id="lastname"
                            autoComplete="family-name"
                            placeholder={t("form.17")}
                            className={
                              !!errors.lastname
                                ? "mt-1  focus:ring-green-600 focus:border-green-600 block w-full shadow-sm sm:text-sm border-red-700 rounded-md"
                                : "mt-1  focus:ring-green-600 focus:border-green-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            }
                            value={form.lastname}
                            onChange={(e) =>
                              setField("lastname", e.target.value)
                            }
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-4">
                          <label
                            htmlFor="email-address"
                            className="flex items-center text-sm font-medium text-gray-700"
                          >
                            {t("form.2")}
                            <span>
                              <BsAsterisk className="ml-2 text-xs" />
                            </span>
                          </label>
                          <input
                            type="text"
                            name="email"
                            id="email"
                            autoComplete="email"
                            placeholder={t("form.2")}
                            className={
                              !!errors.email
                                ? "mt-1  focus:ring-green-600 focus:border-green-600 block w-full shadow-sm sm:text-sm border-red-700 rounded-md"
                                : "mt-1  focus:ring-green-600 focus:border-green-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            }
                            value={form.email}
                            onChange={(e) => setField("email", e.target.value)}
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="country"
                            className="flex items-center text-sm font-medium text-gray-700"
                          >
                            {t("form.3")}
                            <span>
                              <BsAsterisk className="ml-2 text-xs" />
                            </span>
                          </label>
                          {/* <select
                            id="country"
                            name="country"
                            autoComplete="country-name"
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none  focus:ring-green-600 focus:border-green-600 sm:text-sm"
                          > */}
                          <CountrySelect
                            className={"inline"}
                            placeholder={t("form.3")}
                            id="country"
                            throwInvalidValueError={true}
                            autoComplete="of"
                            valueAs="id"
                            value={form.country}
                            onChange={(selected) => {
                              setField("country", selected);
                            }}
                          />
                          {/* </select> */}
                        </div>

                        <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                          <label
                            htmlFor="city"
                            className="flex items-center text-sm font-medium text-gray-700"
                          >
                            {t("form.4")}
                            <span>
                              <BsAsterisk className="ml-2 text-xs" />
                            </span>
                          </label>
                          <input
                            type="text"
                            name="city"
                            id="city"
                            placeholder={t("form.4")}
                            autoComplete="address-level2"
                            className={
                              !!errors.city
                                ? "mt-1  focus:ring-green-600 focus:border-green-600 block w-full shadow-sm sm:text-sm border-red-700 rounded-md"
                                : "mt-1  focus:ring-green-600 focus:border-green-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            }
                            value={form.city}
                            onChange={(e) => setField("city", e.target.value)}
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                          <label
                            htmlFor="region"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {t("form.5")}
                          </label>
                          <input
                            type="text"
                            name="state"
                            id="region"
                            placeholder={t("form.5")}
                            autoComplete="address-level1"
                            value={form.region}
                            onChange={(e) =>
                              setField("region", e.target.value)
                            }
                            className={
                              !!errors.region
                                ? "mt-1  focus:ring-green-600 focus:border-green-600 block w-full shadow-sm sm:text-sm border-red-700 rounded-md"
                                : "mt-1  focus:ring-green-600 focus:border-green-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            }
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                          <label
                            htmlFor="postal-code"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {t("form.6")}
                          </label>
                          <input
                            type="text"
                            name="postalcode"
                            placeholder={t("form.6")}
                            id="postalcode"
                            autoComplete="postal-code"
                            className="mt-1  focus:ring-green-600 focus:border-green-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            value={form.zip}
                            onChange={(e) => setField("postalcode", e.target.value)}
                          />
                        </div>

                        {/* <div className="col-span-6 sm:col-span-4">
                          <label
                            htmlFor="company-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Company Name
                          </label>
                          <input
                            type="text"
                            name="company-name"
                            id="company_name"
                            autoComplete="company-name"
                            placeholder="Company Name"
                            className="mt-1  focus:ring-green-600 focus:border-green-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            value={form.companyName}
                            onChange={(e) =>
                              setField("companyName", e.target.value)
                            }
                          />
                        </div> */}
                      </div>

                      <div className="grid md:grid-cols-2 md:gap-6 mt-8">
                        <div className="relative z-0 w-full group">
                          <label
                            htmlFor="company-name"
                            className="flex items-center mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            {t("form.7")}
                            <span>
                              <BsAsterisk className="ml-2 text-xs" />
                            </span>
                          </label>
                          <input
                            type="text"
                            name="companyname"
                            id="companyname"
                            autoComplete="company-name"
                            placeholder={t("form.7")}
                            className="mt-1  focus:ring-green-600 focus:border-green-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            value={form.companyname}
                            onChange={(e) =>
                              setField("companyname", e.target.value)
                            }
                          />
                        </div>

                        <div className="relative z-0 w-full group">
                          <label
                            htmlFor="phone"
                            className="flex items-center mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            {t("form.18")}
                            <span>
                              <BsAsterisk className="ml-2 text-xs" />
                            </span>
                          </label>
                          <input
                            type="tel"
                            name="phone"
                            id="phone"
                            placeholder="+237 673 993 113"
                            className="mt-1  focus:ring-green-600 focus:border-green-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            value={form.phone}
                            onChange={(e) =>
                              setField("phone", e.target.value)
                            }
                          />
                        </div>
                      </div>

                      <div className="grid md:grid-cols-2 md:gap-6 mt-8">
                        <div className="relative z-0 w-full group">
                          <label
                            htmlFor="agreement"
                            className="flex items-center mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            {t("form.21")}
                          </label>
                          <input
                            type="text"
                            name="agreement"
                            id="agreement"
                            autoComplete="agreement"
                            className="mt-1  focus:ring-green-600 focus:border-green-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            value={form.agreement}
                            onChange={(e) =>
                              setField("agreement", e.target.value)
                            }
                          />
                        </div>

                        <div className="relative z-0 w-full group">
                          <label
                            htmlFor="numcontrib"
                            className="flex items-center mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            {t("form.22")}
                          </label>
                          <input
                            type="text"
                            name="numcontrib"
                            id="numcontrib"
                            placeholder=""
                            className="mt-1  focus:ring-green-600 focus:border-green-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            value={form.numcontrib}
                            onChange={(e) =>
                              setField("numcontrib", e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-span-6 sm:col-span-3 mt-4">
                        <label
                          htmlFor="country"
                          className="flex items-center text-sm font-medium text-gray-700 mb-2"
                        >
                          {t("form.8")}
                          <span>
                            <BsAsterisk className="ml-2 text-xs" />
                          </span>
                        </label>
                        <Select
                          className="text-sm focus:ring-green-600 focus:border-green-600"
                          placeholder={t("form.19")}
                          name="speciality"
                          options={specialities}
                          value={form.speciality}
                          onChange={(selected) => {
                            setField("speciality", selected);
                          }}
                        />
                      </div>

                      <div className="mt-6">
                        <label
                          htmlFor="about"
                          className="block text-sm font-medium text-gray-700"
                        >
                          {" "}
                          {t("form.9")}
                        </label>
                        <div className="mt-1">
                          <textarea
                            id="about"
                            name="message"
                            rows="3"
                            className="shadow-sm  focus:ring-green-600 focus:border-green-600 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                            placeholder="you@example.com"
                            value={form.about}
                            onChange={(e) => setField("about", e.target.value)}
                          ></textarea>
                        </div>
                        <p className="mt-2 text-sm text-gray-500">
                          {t("form.10")}
                        </p>
                      </div>

                      <div className="grid grid-cols-3 gap-6 mt-4">
                        <div className="col-span-3 sm:col-span-2">
                          <label
                            htmlFor="company-website"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {" "}
                            {t("form.11")}
                          </label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                              {" "}
                              https://{" "}
                            </span>
                            <input
                              type="text"
                              name="company-website"
                              id="website"
                              className=" focus:ring-green-600 focus:border-green-600 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                              placeholder="www.example.com"
                              value={form.website}
                              onChange={(e) =>
                                setField("website", e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mt-4">
                        <label className="block text-sm font-medium text-gray-700">
                          {" "}
                          Photo{" "}
                        </label>
                        <div className="mt-1 flex items-center">
                          {form.logo ? (<span className="inline-block h-12 w-12 rounded-lg overflow-hidden bg-gray-100">
                            <img
                              className="h-full w-full text-gray-300"
                              src={form.logo}
                            />
                            <span>{form.logo.name}</span>
                          </span>) : 
                          (<span className="inline-block h-12 w-12 rounded-lg overflow-hidden bg-gray-100">
                          <svg
                            className="h-full w-full text-gray-300"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                          </svg>
                        </span>)}
                          {/* <button
                            type="button"
                            className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            Change
                          </button> */}
                        </div>
                      </div>
                      <div className="mt-4">
                        <label className="block text-sm font-medium text-gray-700">
                          {t("form.12")}
                        </label>
                        <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                          <div className="space-y-1 text-center">
                            <svg
                              className="mx-auto h-12 w-12 text-gray-400"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 48 48"
                              aria-hidden="true"
                            >
                              <path
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            <div className="flex text-sm text-gray-600">
                              <label
                                htmlFor="file-upload"
                                className="relative cursor-pointer bg-white rounded-md font-medium text-green-700 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                              >
                                <span> {t("form.13")}</span>
                                <input
                                  id="file-upload"
                                  name="file-upload"
                                  type="file"
                                  className="sr-only"
                                  onChange={(e) =>
                                    setField("logo", e.target.files[0])
                                  }
                                />
                              </label>
                              <p className="pl-1"> {t("form.14")}</p>
                            </div>
                            <p className="text-xs text-gray-500">
                              PNG, JPG, GIF up to 10MB
                            </p>
                          </div>
                        </div>

                        <div className="px-4 py-3 bg-white text-right sm:px">
                          <Button type="submit">
                            {t("form.15")}
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6"></div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {/* <div className="hidden sm:block" aria-hidden="true">
            <div className="py-5">
              <div className="border-t border-gray-200"></div>
            </div>
          </div> */}
        </div>

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200"></div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Partner;
