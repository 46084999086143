import {
    createAsyncThunk,
    createSlice,
    isRejectedWithValue,
  } from "@reduxjs/toolkit";
  import axios from "axios";
  import { config } from "../../config/config";
  
  const initialState = {
    transporter: null,
    error: false,
    loading: false,
  };
export const getTransporter = createAsyncThunk('/transporter', async() =>{
    try{
        const token = JSON.parse(localStorage.getItem("user"));
        const user_token = token.data.access_token;
        const getTrans = await axios.get(`${config.app.api_url}/transporter`, {
            headers:{
                Authorization: "Bearer " + user_token,
            }
        })
        return getTrans?.data?.data
    }catch(error){

    }
})

export const getDestination = createAsyncThunk('/destination', async() =>{
  try{
      const token = JSON.parse(localStorage.getItem("user"));
      const user_token = token.data.access_token;
      const getTrans = await axios.get(`${config.app.api_url}/destination`, {
          headers:{
              Authorization: "Bearer " + user_token,
          }
      })
      return getTrans?.data?.data
  }catch(error){

  }
})

export const getCost = createAsyncThunk('/cost', async() =>{
  try{
      const token = JSON.parse(localStorage.getItem("user"));
      const user_token = token.data.access_token;
      const getTrans = await axios.get(`${config.app.api_url}/cost`, {
          headers:{
              Authorization: "Bearer " + user_token,
          }
      })
      return getTrans?.data?.data
  }catch(error){

  }
})

export const getCostId = createAsyncThunk('/cost/id', async(id) =>{
  try{
      const token = JSON.parse(localStorage.getItem("user"));
      const user_token = token.data.access_token;
      const getTrans = await axios.get(`${config.app.api_url}/cost/${id}`, {
          headers:{
              Authorization: "Bearer " + user_token,
          }
      })
      return getTrans
  }catch(error){

  }
})

export const getVechile = createAsyncThunk('/vehicle', async() =>{
  try{
      const token = JSON.parse(localStorage.getItem("user"));
      const user_token = token.data.access_token;
      const getTrans = await axios.get(`${config.app.api_url}/vehicle`, {
          headers:{
              Authorization: "Bearer " + user_token,
          }
      })
      // console.log('cars from slice', getTrans.data)
      return getTrans?.data?.data
  }catch(error){

  }
})

export const getVechileId = createAsyncThunk('/vehicle/id', async(id) =>{
  try{
      const token = JSON.parse(localStorage.getItem("user"));
      const user_token = token.data.access_token;
      const getTrans = await axios.get(`${config.app.api_url}/vehicle/${id}`, {
          headers:{
              Authorization: "Bearer " + user_token,
          }
      })
      // console.log('cars from slice', getTrans.data)
      return getTrans?.data
  }catch(error){

  }
})

//get cost for checkout products
export const findCost = createAsyncThunk('/cost/findCost', async(data) =>{
    try{
        const token = JSON.parse(localStorage.getItem("user"));
        const user_token = token.data.access_token;
        const getTrans = await axios.post(`${config.app.api_url}/cost/findCost`,data, {
            headers:{
                Authorization: "Bearer " + user_token,
            }
        })
        return getTrans?.data
    }catch(error){
  
    }
  })


  const transporterSlice = createSlice({
    name: "transporter",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      //register uer
     
    },
  });
  
  export default transporterSlice.reducer;