export const config = {
    app: {
      api_url: "https://api.kalio.io/api",
      // api_url:"https://api-test.kalio.io/api",
      python_api_url: "http://app.kalio.io",
      chat_api_url: "https://kalio-chat.herokuapp.com/",
      call_app_id: 6374,
      call_auth_key: "4ctQCmhVCQwMrbn",
      call_auth_secret: "9AAtvPTZBuZaeSM",
    },
    CINET: {
      API_KEY: "441926940628af072c360e7.68632980",
      APP_ID: "914426",
    },
    video: {
      appId: 6374,
      authKey: "4ctQCmhVCQwMrbn",
      authSecret: "9AAtvPTZBuZaeSM",
      url: "wss://kalio-call.herokuapp.com",
    },
  };