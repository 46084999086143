import {
    createAsyncThunk,
    createSlice,
    isRejectedWithValue,
  } from "@reduxjs/toolkit";
  import axios from "axios";
  import { config } from "../../config/config";
  
  const initialState = {
    posts: null,
    tutorials:null,
    error: false,
    loading: false,
  };
export const getPost = createAsyncThunk('/post', async(lan) =>{
    try{
        const token = JSON.parse(localStorage.getItem("user"));
        const user_token = token.data.access_token;
        const getTrans = await axios.get(`${config.app.api_url}/post/${lan}`, {
            headers:{
                Authorization: "Bearer " + user_token,
            }
        })
        
        return getTrans?.data?.data
    }catch(error){

    }
})

export const getTutorial = createAsyncThunk('/tutorial', async(lan) =>{
  try{
      const token = JSON.parse(localStorage.getItem("user"));
      const user_token = token.data.access_token;
      const getTrans = await axios.get(`${config.app.api_url}/tutorial/${lan}`, {
          headers:{
              Authorization: "Bearer " + user_token,
          }
      })
      console.log('tutorials', getTrans)
      return getTrans?.data?.data
  }catch(error){

  }
})


  const postTutorial = createSlice({
    name: "postTutorial",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      //register uer
     
    },
  });
  
  export default postTutorial.reducer;