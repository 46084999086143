import React from "react";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();
  return (
    <>
      {/* <Nav /> */}
      <section className="text-gray-400 bg-green-700 body-font mb-20">
        <div className="container mx-auto pt-10 flex px-5 py-10 md:flex-row flex-col items-center">
          <div className="lg:flex-grow pt-12 md:pt-16 sm:pt-16 md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white sm:mt-10 lg:mt-10 text-justify lg:text-left">
              {t("contact.1")}
              {/* <br className="hidden lg:inline-block" />
              readymade gluten */}
            </h1>
            {/* <p className="leading-relaxed text-gray-100 text-justify lg:text-left">
              Agriculture has been connected to humankind for thousands of years
              this makes farming a business with a long tradition. Smart Farming
              with Kalio is a new concept in modern information and
              communication technology and changes the way we work in
              agriculture. Using the Internet of Things Technology we increase
              the quality and quantity of products.
            </p> */}
          </div>
          {/* <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 lg:pt-20 ">
            <img
              className="object-cover object-center rounded"
              alt="hero"
              src={img}
            />
          </div> */}
        </div>
      </section>
      <ContactForm />
      {/* <Feature /> */}
      <Footer />
    </>
  );
};

export default Contact;
