import React, { useTransition } from "react";
import Footer from "../components/Footer";
import Nav from "../components/Nav";
import { BsShop, BsFillPersonFill } from "react-icons/bs";
import { IoMapOutline } from "react-icons/io5";
import { TbPlant, TbSeeding } from "react-icons/tb";
import { MdSensors, MdShop } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { ImLab } from "react-icons/im";

const Services = () => {
  const { t } = useTranslation();

  const services = [
    // {
    //   title: t("feature.10"),
    //   description: t("services.7"),
    //   icon: <BsShop />,
    // },
    // {
    //   title: t("feature.15"),
    //   description: t("services.10"),
    //   icon: <MdShop />,
    // },
    {
      title: t("feature.11"),
      description: t("services.8"),
      icon: <BsFillPersonFill />,
    },
    {
      title: t("feature.12"),
      description: t("services.9"),
      icon: <ImLab />,
    },
  ];

  const solutions = [
    {
      title: t("feature.3"),
      description: t("services.3"),
      icon: <TbPlant />,
    },
    {
      title: t("feature.5"),
      description: t("services.4"),
      icon: <MdSensors />,
    },
    {
      title: t("feature.13"),
      description: t("services.12"),
      icon: <TbSeeding />,
    },
  ];

  return (
    <>
      {/* <Nav /> */}
      <section className="text-gray-400 bg-green-700 body-font mb-20">
        <div className="container mx-auto pt-10 flex px-5 md:flex-row flex-col items-center">
          <div className="lg:flex-grow pt-12 md:pt-16 sm:pt-16 md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center justify-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white sm:mt-10 mt-10 lg:mt-10 text-justify lg:text-left">
              {t("services.1")}
              {/* <br className="hidden lg:inline-block" />
              readymade gluten */}
            </h1>
            {/* <p className="leading-relaxed text-gray-100 text-justify lg:text-left">
              Agriculture has been connected to humankind for thousands of years
              this makes farming a business with a long tradition. Smart Farming
              with Kalio is a new concept in modern information and
              communication technology and changes the way we work in
              agriculture. Using the Internet of Things Technology we increase
              the quality and quantity of products.
            </p> */}
          </div>
          {/* <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 lg:pt-20 ">
            <img
              className="object-cover object-center rounded"
              alt="hero"
              src={img}
            />
          </div> */}
        </div>
      </section>

      <section className="text-gray-600 body-font pb-20">
        <div className="container px-5 mx-auto">
          <div className="flex flex-col text-center w-full mb-10 mt-20">
            {/* <h2 className="text-xs text-green-700 tracking-widest font-medium title-font mb-1">
              Solutions & Services
            </h2> */}
            <h1 style={{fontSize:'30px', width:'70%', margin:"0 auto"}}>
              {t("services.2")}
            </h1>
          </div>
          <div 
          // className="flex flex-wrap gap-6 m-auto"
          style={{width:'100%', display:'flex', justifyContent:'space-evenly', flexWrap:'wrap'}}
          >
            {solutions.map((solution) => (
              <div key={solution.title} className="p-4 md:w-1/3">
                <div className="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
                  <div className="flex items-center mb-3">
                    <div className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-green-700 text-white flex-shrink-0">
                      {solution.icon}
                    </div>
                    <h2 className="text-gray-900 text-m title-font font-medium">
                      {solution.title}
                    </h2>
                  </div>
                  <div className="flex-grow">
                    <p className="leading-relaxed text-base">
                      {solution.description}
                    </p>
                    {/* <a className="mt-3 text-green-700 inline-flex items-center">
                      Subscribe solution
                      <FiArrowRight />
                    </a> */}
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="flex flex-col text-center w-full mb-10 mt-20">
            {/* <h2 className="text-xs text-green-700 tracking-widest font-medium title-font mb-1">
              Solutions & Services
            </h2> */}
            <h1 style={{fontSize:'30px', width:'70%', margin:"0 auto"}}>
              {t("services.6")}
            </h1>
          </div>
          <div 
          // className="flex flex-wrap -m-4"
          style={{width:'100%', display:'flex', justifyContent:'space-evenly', flexWrap:'wrap'}}
          >
            {services.map((service) => (
              <div className="p-4 md:w-1/3" key={service.title}>
                <div className="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
                  <div className="flex items-center mb-3">
                    <div className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-green-700 text-white flex-shrink-0">
                      {service.icon}
                    </div>
                    <h2 className="text-gray-900 text-m title-font font-medium">
                      {service.title}
                    </h2>
                  </div>
                  <div className="flex-grow">
                    <p className="leading-relaxed text-base">
                      {service.description}
                    </p>
                    {/* <a className="mt-3 text-green-700 inline-flex items-center">
                      Subscribe service
                      <FiArrowRight />
                    </a> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Services;
