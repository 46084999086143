import React from "react";
import logo from "../assets/logo.PNG";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const HomeHero = ({ button, img, text1 }) => {
  const { t } = useTranslation();
  const navigate = useNavigate()

  return (
    <section className="text-gray-600 body-font relative mt-[6rem] mb-10 lg:mb-0">
      <img
        className="object-cover object-center absolute inset-0 h-full w-full"
        alt="hero"
        src={img}
      />
      <div className="absolute inset-0 bg-gray-500/60 z-0" />

      <div className="container mx-auto flex px-5 lg:pt-20 pb-14 md:flex-row flex-col items-center relative z-0">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 md:mb-0 items-center text-center">
          {/* <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-green-700 text-center md:text-left lg:text-left">
            {title}
          </h1> */}
          <img
            className="object-fit object-center mt-12 inset-0 ml-0 lg:ml-36 lg:mt-10 w-60 lg:absolute"
            alt="hero"
            src={logo}
          />
        </div>
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 lg:mr-16 mr-0 mt-20 lg:mt-0">
          <p className="mb-8 leading-relaxed text-left lg:text-left text-white text-3xl font-bold">
            {t("intro.1")}{" "}
            {/* <span className="text-white">{t("intro.2")}</span>{" "}
            {t("intro.3")}{" "}
            <span className="text-white">{t("intro.4")} </span>
            {t("intro.5")}{" "}
            <span className="text-white">{t("intro.6")} </span>
            {t("intro.7")} */}
          </p>
          <p className="mb-8 leading-relaxed lg:text-justify">{text1}</p>
        </div>
      </div>
      <div onClick={() => navigate("/about")} className="flex justify-center relative pb-14">
        {button}

        {/* <button className="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">Button</button>
        <button className="ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg">Button</button> */}
      </div>
    </section>
  );
};

export default HomeHero;
