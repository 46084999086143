import * as React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.css"; // or include from a CDN
import "react-bootstrap-country-select/dist/react-bootstrap-country-select.css";
import { BrowserRouter } from "react-router-dom";
import "./index.css";

import App from "./App";
import "./utils/i18n";
import { Provider } from "react-redux";
import { store } from "./state/store/store";
import 'react-toastify/dist/ReactToastify.css';
import { createTheme, ThemeProvider } from '@mui/material';
const theme = createTheme({
  palette: {
    primary: {
      main:'#1C9A4E'
    },
    danger:{
      main:'rgb(217 0 0)'
    }
  
  },
  typography:{
    fontFamily: ['Poppins']
  }
})
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
    <BrowserRouter>
      <React.Suspense fallback={<div>Loading</div>}>
        <App />
      </React.Suspense>
    </BrowserRouter>
    </ThemeProvider>
    </Provider>
  </React.StrictMode>
);
