import React from "react";

const HeroButton = (props) => {
  return (
    <button
      onClick={props.onClick}
      type="button"
      className="hover:text-white font-medium bg-green-700 hover:border-none hover:bg-green-600 hover:border-green-600  focus:outline-none  rounded-full text-sm px-4 py-3 text-center inline-flex items-center dark:bg-green-700 dark:hover:bg-green-700 dark:text-white text-white"
    >
      {props.children}
    </button>
  );
};

export default HeroButton;
