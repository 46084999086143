import React from "react";
import Hero from "../components/Hero";
import Nav from "../components/Nav";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import Partners from "../components/Partners";
import img from "../assets/sensor.png";
import dataimg from "../assets/hero-img.png";
import { useNavigate } from "react-router-dom";
import { TbCloudComputing } from "react-icons/tb";
import { CgData } from "react-icons/cg";
import { RiComputerLine } from "react-icons/ri";
import { IoHelpBuoyOutline, IoSyncCircleOutline } from "react-icons/io5";
import { VscTools } from "react-icons/vsc";
import { useTranslation } from "react-i18next";

const features = [
  {
    title: "Remote sensing",
    description:
      "the critical information you need to know about field and environmental conditions so that you can make the best possible decisions about your irrigation schedules",
    icon: <CgData />,
  },
  {
    title: "IoT in one platform",
    description:
      "Spreading out our applications, to remotely collect data with secure connectivity, and then execute sensor management",
    icon: <TbCloudComputing />,
  },
  {
    title: "Real-time data Dashboard",
    description:
      "With a well structured and easy to use user interface dashboard, visualize and know the conditions of your farm",
    icon: <RiComputerLine />,
  },
  {
    title: "High accuracy and granularity",
    description:
      "Our sensors collect accurate data for analysis and treatment before sending them for your proper usage",
    icon: <IoHelpBuoyOutline />,
  },
  {
    title: "Proprietary AI technology",
    description:
      "We use an advanced machine learning algorithm to be able to detect and tell the kind of disease that has attacked your plant",
    icon: <VscTools />,
  },
  {
    title: "Farm Visualization",
    description:
      "No need to always be at your site as your farm conditions and data are being sent directly to your dashboard at alltimes.",
    icon: <IoSyncCircleOutline />,
  },
];

const About = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const aboutHero = {
    title: t("about.1"),
    text: t("about.2"),
    image: dataimg,
    button: (
      <button
        onClick={() => navigate("/contact")}
        className="inline-flex text-white bg-green-700 border-0 py-2 px-6 focus:outline-none hover:bg-green-600 rounded text-lg"
      >
        {t("about.3")}
      </button>
    ),
    button1: (
      <button className="ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg">
        Get in touch
      </button>
    ),
  };
  return (
    <>
      {/* <Nav /> */}
      <Hero
        title={aboutHero.title}
        text={aboutHero.text}
        img={aboutHero.image}
        button={aboutHero.button}
        // button1={aboutHero.button1}
      />

      {/* <section className="bg-white dark:bg-gray-900">
        <div className="container px-6 py-10 mx-auto">
          <h1 className="text-3xl font-semibold text-gray-800 capitalize lg:text-4xl">
            explore the all our
            <span className="underline decoration-green-700"> Features</span>
          </h1>

          <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
            We bring you the best.
          </p>

          <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-12 md:grid-cols-2 xl:grid-cols-3">
            {features.map((feature) => (
              <div
                key={feature.title}
                className="p-8 space-y-3 border-2 border-green-700 dark:border-green-300 rounded-xl"
              >
                <a
                  href="#"
                  className="inline-flex p-2 text-green-700 capitalize transition-colors duration-200 bg-blue-100 rounded-full hover:underline hover:text-green-700"
                >
                  {feature.icon}
                </a>

                <h1 className="text-2xl font-semibold text-gray-700 capitalize dark:text-white">
                  {feature.title}
                </h1>

                <p className="text-gray-600 dark:text-gray-300">
                  {feature.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section> */}

      {/* <section className=" bg-white body-font py-10">
        <div className="container mx-auto flex px-5 md:flex-row flex-col items-center">
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 md:mb-0 mb-10">
            <img
              className="object-cover object-center rounded"
              alt="hero"
              src={img}
            />
          </div>
          <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
            <h1 className="title-font sm:text-3xl text-3xl font-medium text-black px-4 mb-4">
              What we Offer and its advantages
            </h1>
            <p className="mb-8 leading-relaxed  text-gray-600 text-justify px-4">
              We combine and consolidate data across farm operations (crops,
              animals, feed, soil, waste, vehicles, equipment and people) for
              farm managers to improve animal and/or crop health and safety, all
              while increasing efficiency and visibility to farm stakeholders
              and customers.
            </p>
            <p className="mb-8 leading-relaxed  text-gray-600 text-justify px-4">
              Advanced digital monitoring of lagoon levels to optimize crop
              fertilization and prevent spillage and contamination. This
              end-to-end solution includes field-proven sensors for data
              capture, cloud-based data processing and custom reporting.
            </p>
            <p className="mb-8 leading-relaxed  text-gray-600 text-justify px-4">
              IoT monitoring of soil conditions to optimize crop yield while
              reducing environmental impact.
            </p>
          </div>
        </div>
      </section> */}

      {/* <Partners /> */}
      {/* <ContactForm /> */}
      <Footer />
    </>
  );
};

export default About;
