import axios from "axios";
import { config } from "../../config/config";
const token = JSON.parse(localStorage.getItem("user"));
const user_token = token?.data?.access_token;
const API = axios.create({ baseURL: config.app.api_url });

//services routes
export const requestAccountDeletion = (data) =>
  API.post("/user/request-verification-code", data);

export const confirmAccountDeletion = (data) =>
  API.post("/user/verify-deletion", data);
