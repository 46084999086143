import React from "react";
import mockUp from "../assets/kalio.png";
import { SiGoogleplay, SiApple } from "react-icons/si";
import { useTranslation } from "react-i18next";

const CTA = () => {
  const { t } = useTranslation();

  return (
    <section className="text-gray-400 bg-green-700 body-font mb-10">
      <div className="container mx-auto flex px-5 py-10 md:flex-row flex-col items-center">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">
            {t("cta.1")}
            <br className="hidden lg:inline-block" />
            {t("cta.2")}
          </h1>

          <div className="justify-center px-20 items-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
            <a
              href="https://play.google.com/store/apps/details?id=com.kalio.kalio"
              className="w-full sm:w-auto  bg-gray-100 hover:bg-gray-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-gray-300 text-gray-800 rounded-lg inline-flex items-center justify-center px-4 py-2.5"
            >
              <SiGoogleplay className="mr-3 w-7 h-7" />
              <div className="text-left">
                <div className="mb-1 text-xs"> {t("cta.3")}</div>
                <div className="-mt-1 font-sans text-sm font-semibold">
                  {t("cta.4")}
                </div>
              </div>
            </a>
            <a
              href="#"
              className="w-full sm:w-auto  bg-gray-800 hover:bg-gray-100 hover:text-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 text-gray-100 rounded-lg inline-flex items-center justify-center px-4 py-2.5"
            >
              <SiApple className="mr-3 w-7 h-7" />
              {t("cta.7")}
              <div className="text-left">
                {/* <div className="mb-1 text-xs">{t("cta.5")}</div> */}
                <div className="-mt-1 font-sans text-sm font-semibold">
                  {/* {t("cta.6")} */}
                </div>
              </div>
            </a>
          </div>

          {/* <div className="px-5 flex justify-between">
            <button className="inline-flex items-center text-slate-900 bg-gray-100 border-0 py-2.5 px-3 hover:bg-slate-900 hover:text-white rounded text-lg">
              <span className="text-left mr-2">
                <SiGoogleplay />
              </span>
              Google Play
            </button>
            <button className="ml-4 inline-flex items-center text-gray-100 bg-slate-900 border-0 py-2.5 px-3 hover:bg-gray-100 hover:text-slate-900 rounded text-lg">
              <span className="text-left mr-2">
                <SiApple />
              </span>
              Apple Store
            </button>
          </div> */}
        </div>
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
          <img
            className="object-cover object-center rounded"
            alt="Kalio-app"
            src={mockUp}
          />
        </div>
      </div>
    </section>
  );
};

export default CTA;
