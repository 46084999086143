import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { BsCart } from "react-icons/bs";
// import Button from "./Button";

const Nav = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const activeLink = "text-green-600 font-bold hover:text-green-600";
  const normalLink =
    "text-gray-800 hover:text-green-600 hover:font-semiBold font-poppins duration-500";
  let Links = [
    { name: t("nav.1"), link: "/" },
    { name: t("nav.3"), link: "/services" },
    { name: t("nav.4"), link: "/become-partner" },
    { name: t("nav.5"), link: "/contact" },
    { name: t("nav.2"), link: "/about" },
    { name: t("nav.7"), link: "/blogs" },
    { name: 'Account', link: "/delete-my-account" },
  ];
  let [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);

  function handleClick(lang) {
    i18next.changeLanguage(lang);
    setActive(!active);
  }
  return (
    <div className="shadow-md w-full fixed top-0 left-0 z-10 nav-style">
      <div className="md:flex items-center justify-between bg-white py-4 md:px-10 px-7">
        <div
          className="font-bold text-2xl cursor-pointer flex items-center font-[Poppins] 
      text-gray-800"
          onClick={() => navigate("/")}
        >
          <span className="text-3xl mb-9 text-green-700 mr-1 pt-2">
            <img src="assets/images/logo.PNG" width={50} height={50} />
          </span>
          Kalio
        </div>

        <div
          onClick={() => setOpen(!open)}
          className="text-3xl absolute right-8 top-6 cursor-pointer md:hidden"
        >
          <ion-icon name={open ? "close" : "menu"}></ion-icon>
        </div>
        <ul
        
          className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static bg-white md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${
            open ? "mt-15" : "top-[-490px]"
          }`}
        >
          {Links.map((link) => (
            <li  key={link.name} className="md:ml-10 text-l md:my-0 my-7">
              <NavLink
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
                to={link.link}
              >
                {link.name}
              </NavLink>
            </li>
          ))}
           </ul>
          <>
            <div
              className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static bg-white md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${
                open ? "top-[430px]" : "top-[-490px]"
              }`}
            >
              <button
                onClick={() => handleClick("en")}
                type="button"
                className={`py-1.5 px-2 items-center mr-2 mb-2 text-sm font-medium rounded-full focus:z-10 focus:ring-4 focus:ring-gray-200 ${
                  !active
                    ? "bg-green-700 text-gray-100 hover:bg-gray-100 hover:text-green-700 "
                    : " text-green-700 bg-gray-100 hover:text-gray-100  hover:bg-green-700"
                }`}
              >
                en
              </button>
              <button
                onClick={() => handleClick("fr")}
                type="button"
                className={`py-1.5 px-2.5 items-center mr-2 mb-2 text-sm font-medium rounded-full focus:z-10 focus:ring-4 focus:ring-gray-200 ${
                  active
                    ? "bg-green-700 text-gray-100 hover:bg-gray-100 hover:text-green-700 "
                    : " text-green-700 bg-gray-100 hover:text-gray-100  hover:bg-green-700"
                }`}
              >
                fr
              </button>
            </div>
          </>
       
      </div>
    </div>
  );
};

export default Nav;
