import React from 'react'
import { Routes, Route } from "react-router-dom";

import About from "./pages/About";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import BecomePartner from "./pages/BecomePartner";
import Services from "./pages/Services";
import Privacy from "./pages/Privacy";
import BlogPage from './pages/blogPage';
import BlogDetail from './pages/blogDetail';
import PostDetail from './pages/postDetail';

export default function Router() {
  return (
    <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/about" element={<About />} />
    <Route path="/services" element={<Services />} />
    <Route path="/contact" element={<Contact />} />
    <Route path="/become-partner" element={<BecomePartner />} />
    <Route path="/privacy" element={<Privacy />} />
    <Route path="/blogs" element={<BlogPage />} />
    <Route path="/blog/:id" element={<BlogDetail />} />
    <Route path="/post/:id" element={<PostDetail />} />
  </Routes>
  )
}
