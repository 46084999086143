import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { requestVerification, userLogin } from "../state/slices/authSlice";
import { useLocation, useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "80%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  // borderRadius:'10px',
  // border: '1px solid rgb(223, 223, 223)',
  // boxShadow: 24,
  height: "100vh",
  p: 4,
};
function GetOtp(props) {
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(50);
  const { loading } = useSelector((store) => store.auth);
  const [otp, setOtp] = useState();
  const dispatch = useDispatch();
  const state = useLocation();
  const navigate = useNavigate();
  //timer
  console.log('verify state',state?.state?.phoneNumber)
  useEffect(() => {
    const interval = setInterval(() => {
      if(!state.phoneNumber){
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
  
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(interval);
          } else {
            setSeconds(59);
            setMinutes(minutes - 1);
          }
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds, minutes]);

  const verifyOtp = () => {
    const newOtp = otp;
    dispatch(
      userLogin({
        phone: state?.state?.phoneNumber,
        code: newOtp,
      })
    ).then((res) => {
      console.log(res);
      if (res?.payload?.status === 201) {
        navigate("/marketplace/my-orders");
      }
    });
  };

  // resend verification code
  const resendCode = () => {
    dispatch(
      requestVerification({
        phone: state?.state?.phoneNumber,
      })
    );
  };
  return (
    <div>
      <Modal
        open={props.open}
        // onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* <img
          src="/assets/images/smart.png"
          style={{ height: "90vh", marginTop: "5rem" }}
          alt=""
        /> */}
        <Box sx={style} className="otp-modal">
          <p className="dis-msg">
            Please provide the code that was just sent to
            <span>{state?.state?.phoneNumber}</span>
          </p>
          <input
            className="otp-input"
            type="text"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
          {loading ? (
            <Button className="confirm">
              Loading........
            </Button>
          ) : (
            <Button className="confirm" onClick={verifyOtp}>
              Confirm
            </Button>
          )}
          {seconds > 0 || minutes > 0 ? (
            <p className="resend">
              Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
              {seconds < 10 ? `0${seconds}` : seconds}
            </p>
          ) : (
            <p className="resend">
              Didn't get the code!!! <span onClick={resendCode}>Resend</span>
            </p>
          )}
        </Box>
      </Modal>
    </div>
  );
}

export default GetOtp;
