import React from "react";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";

const Privacy = () => {
  const { t } = useTranslation();
  return (
    <>
      {/* <Nav /> */}
      <section className="text-gray-400 bg-green-700 body-font mb-20">
        <div className="container mx-auto pt-10 flex px-5 py-10 md:flex-row flex-col items-center">
          <div className="lg:flex-grow pt-12 md:pt-16 sm:pt-16 md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white sm:mt-10 lg:mt-10 text-justify lg:text-left">
              {t("privacy.1")}
              {/* <br className="hidden lg:inline-block" />
              readymade gluten */}
            </h1>
            {/* <p className="leading-relaxed text-gray-100 text-justify lg:text-left">
              Agriculture has been connected to humankind for thousands of years
              this makes farming a business with a long tradition. Smart Farming
              with Kalio is a new concept in modern information and
              communication technology and changes the way we work in
              agriculture. Using the Internet of Things Technology we increase
              the quality and quantity of products.
            </p> */}
          </div>
          {/* <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 lg:pt-20 ">
            <img
              className="object-cover object-center rounded"
              alt="hero"
              src={img}
            />
          </div> */}
        </div>
      </section>

      <h1 className="container title-font text-3xl mx-auto px-5 tet-bold leading-relaxed text-justify lg:text-left">
        {t("privacy.4")}
      </h1>
      <section className="bg-white body-font lg:mb-0 mt-10 mb-16">
        <div className="container mx-auto px-5 py-10 md:flex-row flex-col items-center">
          <p className="leading-relaxed text-justify lg:text-left">
            {t("privacy.2")}
          </p>
          <br />
          <p className="leading-relaxed text-justify lg:text-left">
            {t("privacy.3")}
          </p>
          <br />
          <h1 className="leading-relaxed text-2xl text-justify lg:text-left">
            {t("privacy.5")}
          </h1>
          <p className="leading-relaxed text-justify lg:text-left">
            {t("privacy.6")}
          </p>
          <br />
          <p className="leading-relaxed text-justify lg:text-left">
            {t("privacy.7")}
          </p>
          <br />
          <p className="leading-relaxed text-justify lg:text-left">
            {t("privacy.8")}
          </p>
          <p className="leading-relaxed px-5 text-justify lg:text-left">
            <ol>
              <li><span className="text-2xl text-justify lg:text-left">-</span> {t("privacy.9")}</li>
              <li><span className="text-2xl text-justify lg:text-left">-</span> {t("privacy.10")}</li>
              <li><span className="text-2xl text-justify lg:text-left">-</span> {t("privacy.11")}</li>
            </ol>
          </p>
          <br />
          <h1 className="leading-relaxed text-2xl text-justify lg:text-left">
            {t("privacy.12")}
          </h1>
          <p className="leading-relaxed text-justify lg:text-left">
            {t("privacy.13")}
          </p>
          <p className="leading-relaxed px-5 text-justify lg:text-left">
            <ol>
              <li><span className="text-2xl text-justify lg:text-left">-</span> {t("privacy.14")}</li>
              <li><span className="text-2xl text-justify lg:text-left">-</span> {t("privacy.15")}</li>
              <li><span className="text-2xl text-justify lg:text-left">-</span> {t("privacy.16")}</li>
              <li><span className="text-2xl text-justify lg:text-left">-</span> {t("privacy.17")}</li>
              <li><span className="text-2xl text-justify lg:text-left">-</span> {t("privacy.18")}</li>
              <li><span className="text-2xl text-justify lg:text-left">-</span> {t("privacy.19")}</li>
              <li><span className="text-2xl text-justify lg:text-left">-</span> {t("privacy.20")}</li>
              <li><span className="text-2xl text-justify lg:text-left">-</span> {t("privacy.21")}</li>
              <li><span className="text-2xl text-justify lg:text-left">-</span> {t("privacy.22")}</li>
            </ol>
          </p>
          <p className="leading-relaxed text-justify lg:text-left">
            {t("privacy.23")}
          </p>
          <br />
          <h1 className="leading-relaxed text-2xl text-justify lg:text-left">
            {t("privacy.24")}
          </h1>
          <p className="leading-relaxed text-justify lg:text-left">
            {t("privacy.25")}
          </p>
          <p className="leading-relaxed px-5 text-justify lg:text-left">
            <ol>
              <li><span className="text-2xl text-justify lg:text-left">-</span> {t("privacy.26")}</li>
              <li><span className="text-2xl text-justify lg:text-left">-</span> {t("privacy.27")}</li>
              <li><span className="text-2xl text-justify lg:text-left">-</span> {t("privacy.28")}</li>
              <li><span className="text-2xl text-justify lg:text-left">-</span> {t("privacy.29")}</li>
            </ol>
          </p>
          <br />
          <h1 className="leading-relaxed text-2xl text-justify lg:text-left">
            {t("privacy.30")}
          </h1>
          <p className="leading-relaxed text-justify lg:text-left">
            {t("privacy.31")}
          </p>
          <br />
          <h1 className="leading-relaxed text-2xl text-justify lg:text-left">
            {t("privacy.32")}
          </h1>
          <p className="leading-relaxed text-justify lg:text-left">
            {t("privacy.33")}
          </p>
          <br />
          <p className="leading-relaxed text-justify lg:text-left">
            {t("privacy.34")}
          </p>
          <br />
          <h1 className="leading-relaxed text-2xl text-justify lg:text-left">
            {t("privacy.35")}
          </h1>
          <p className="leading-relaxed text-justify lg:text-left">
            {t("privacy.36")}
          </p>
          <br />
          <h1 className="leading-relaxed text-2xl text-justify lg:text-left">
            {t("privacy.37")}
          </h1>
          <p className="leading-relaxed text-justify lg:text-left">
            {t("privacy.38")}
          </p>
          <br />
          <h1 className="leading-relaxed text-2xl text-justify lg:text-left">
            {t("privacy.39")}
          </h1>
          <p className="leading-relaxed text-justify lg:text-left">
            {t("privacy.40")}
          </p>
          <br />
          <h1 className="leading-relaxed text-2xl text-justify lg:text-left">
            {t("privacy.41")}
          </h1>
          <p className="leading-relaxed text-justify lg:text-left">
            {t("privacy.42")}
          </p>
          <br />
          <h1 className="leading-relaxed text-2xl text-justify lg:text-left">
            {t("privacy.43")}
          </h1>
          <p className="leading-relaxed text-justify lg:text-left">
            {t("privacy.44")}
          </p>
          <br />
          <h1 className="leading-relaxed text-2xl text-justify lg:text-left">
            {t("privacy.45")}
          </h1>
          <p className="leading-relaxed text-justify lg:text-left">
            {t("privacy.46")}
          </p>
          <br />
          <h1 className="leading-relaxed text-2xl text-justify lg:text-left">
            {t("privacy.47")}
          </h1>
          <p className="leading-relaxed text-justify lg:text-left">
            {t("privacy.48")}
          </p>
          <p className="leading-relaxed px-5 text-justify lg:text-left">
            <ol>
              <li><span className="text-2xl text-justify lg:text-left">-</span> {t("privacy.49")}</li>
              <li><span className="text-2xl text-justify lg:text-left">-</span> {t("privacy.50")}</li>
              <li><span className="text-2xl text-justify lg:text-left">-</span> {t("privacy.51")}</li>
              <li><span className="text-2xl text-justify lg:text-left">-</span> {t("privacy.52")}</li>
              <li><span className="text-2xl text-justify lg:text-left">-</span> {t("privacy.53")}</li>
            </ol>
          </p>
          <p className="leading-relaxed text-justify lg:text-left">
            {t("privacy.54")}
          </p>
          <br />
          <h1 className="leading-relaxed text-2xl text-justify lg:text-left">
            {t("privacy.55")}
          </h1>
          <p className="leading-relaxed text-justify lg:text-left">
            {t("privacy.56")}
          </p>
          <br />
          <p className="leading-relaxed text-justify lg:text-left">
            {t("privacy.57")}
          </p>
          <h1 className="leading-relaxed text-2xl text-justify lg:text-left">
            {t("privacy.58")}
          </h1>
        </div>
      </section>
      {/* <Feature /> */}
      <Footer />
    </>
  );
};

export default Privacy;
