import React from "react";
import "./main.scss";
import { Box, Typography } from "@mui/material";
import { Card } from "react-bootstrap";
import { CallMade, FiberManualRecord } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import moment from "moment";

export default function BlogCards(props) {
  const navigate = useNavigate();
  const myFile = new File([props.video], `${props.video}`, {
    type: "video/mp4",
  });
  console.log(myFile);
  return (
    <Box className="blogs-c">
      <Card className="blog-card" style={{ boxShadow: "none", height: "auto" }}>
        <ReactPlayer
          // playing
          controls={true}
          height={'15rem'}
          width={'100%'}
          url={props.video}
        />
        <Typography className="author">
          etah innocent <FiberManualRecord className="point" />
          {moment(props.createdAt).format("DD/MMMM/YY")}
        </Typography>
        <Box className="blog-title">
          <Typography className="title-text">{props.name}</Typography>
          <CallMade
            className="title-text more"
            onClick={() => navigate(`/blog/${props.postId}`, { state: props })}
          />
        </Box>
        <Typography className="blog-desc">{props.description}</Typography>
      </Card>
    </Box>
  );
}
